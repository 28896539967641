module.exports = {
    getInfo: function() {
        let browserDetect, count, dataString, dataProp, index, winOS;

        try {
            browserDetect = {
              init: function () {
                this.browser = this.searchString(this.dataBrowser) || "";
                this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "";
                this.OS = this.getOS(navigator.userAgent) || "";
                this.OsVersion = this.getOsVersion(this.OS, navigator.userAgent) || "";
              },
      
              getOS: function (userAgent) {
                if (userAgent.indexOf("Win") != -1) return "Windows";
                if (userAgent.indexOf("iPad") != -1) return "iPad";
                if (userAgent.indexOf("iPhone") != -1) return "iPhone";
                if (userAgent.indexOf("Mac") != -1) return "Mac";
                if (userAgent.indexOf("Android") != -1) return "Android";
                if (userAgent.indexOf("X11") != -1) return "UNIX";
                if (userAgent.indexOf("Linux") != -1) return "Linux";
              },
      
              getOsVersion: function (os, userAgent) {
      
                if (os == "iPad" || os == "iPhone") {
                  index = userAgent.indexOf("OS ");
                  if (index == -1) return;
                  return userAgent.substring(index + 3, userAgent.length).split(" ")[0].replace(new RegExp("_", 'g'), ".");
                }
      
                if (os == "Mac") {
                  index = userAgent.indexOf("X ");
                  if (index == -1) return;
                  return userAgent.substring(index + 2, userAgent.length).split(")")[0].split(";")[0].replace(new RegExp("_", 'g'), ".");
                }
      
                if (os == "Android") {
                  index = userAgent.indexOf("Android ");
                  if (index == -1) return;
                  return userAgent.substring(index + 8, userAgent.length).split(";")[0];
                }
      
                if (os == "Windows") {
                  index = userAgent.indexOf("Phone ");
      
                  if (index != -1) {
                    return userAgent.substring(index + 6, userAgent.length).split(";")[0];
      
                  } else {
                    index = userAgent.indexOf("Windows ");
                    winOS = userAgent.substring(index + 8, userAgent.length).split(";")[0];
      
                    if (winOS == "NT 10.0") { return "10" }
                    if (winOS == "NT 6.0") { return "Vista" }
                    if (winOS == "NT 5.1") { return "XP" }
                    if (winOS == "NT 6.1") { return "7" }
                    if (winOS == "NT 6.2") { return "8" }
                    if (winOS == "NT 6.3") { return "8.1" }
                  }
                }
              },
              searchString: function (data) {
                for (count = 0; count < data.length; count++) {
                  dataString = data[count].string;
                  dataProp = data[count].prop;
                  this.versionSearchString = data[count].versionSearch || data[count].identity;
                  if (dataString) {
                    if (dataString.indexOf(data[count].subString) != -1)
                      return data[count].identity;
                  } else if (dataProp)
                    return data[count].identity;
                }
              },
              searchVersion: function (dataString) {
                index = dataString.indexOf(this.versionSearchString);
                if (index == -1) return;
                return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
              },
              dataBrowser: [
                {
                  string: navigator.userAgent,
                  subString: "Edge",
                  identity: "Microsoft Edge",
                  versionSearch: "Edge"
                },
                {
                  string: navigator.userAgent,
                  subString: "OPR",
                  identity: "Opera",
                  versionSearch: "OPR"
                },
                {
                  string: navigator.userAgent,
                  subString: "Chrome",
                  identity: "Chrome"
                },
                {
                  string: navigator.userAgent,
                  subString: "OmniWeb",
                  versionSearch: "OmniWeb/",
                  identity: "OmniWeb"
                },
                {
                  string: navigator.userAgent,
                  subString: "Apple",
                  identity: "Safari",
                  versionSearch: "Version"
                },
                {
                  prop: window.opera,
                  identity: "Opera",
                  versionSearch: "Version"
                },
                {
                  string: navigator.vendor,
                  subString: "iCab",
                  identity: "iCab"
                },
                {
                  string: navigator.vendor,
                  subString: "KDE",
                  identity: "Konqueror"
                },
                {
                  string: navigator.userAgent,
                  subString: "Firefox",
                  identity: "Firefox"
                },
                {
                  string: navigator.vendor,
                  subString: "Camino",
                  identity: "Camino"
                },
                {
                  string: navigator.userAgent,
                  subString: "Netscape",
                  identity: "Netscape"
                },
                {
                  string: navigator.userAgent,
                  subString: "MSIE",
                  identity: "Explorer",
                  versionSearch: "MSIE"
                },
                {
                  string: navigator.userAgent,
                  subString: "Gecko",
                  identity: "Explorer",
                  versionSearch: "rv"
                },
                {
                  string: navigator.userAgent,
                  subString: "Mozilla",
                  identity: "Netscape",
                  versionSearch: "Mozilla"
                }
              ]
            };
        } catch (error) { }
      
        browserDetect.init();
        return browserDetect;
    },

    getParameterByName: function(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
  }